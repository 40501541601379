// customize some colors variables.
@import "custom-colors.scss";

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea.form-control,
select.form-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea.form-control:focus,
select.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #000000;
}

select.form-control {
    margin-top: 12px;
}

.navbar {
    z-index: 90;
}

.navbar+.navbar {
    z-index: 80;
}

.fixed-top-2 {
    margin-top: 66px;
}

.navbar-nav > li{
    margin-left:10px;
    margin-right:10px;
}

.open-modal-image-dialog {
    cursor: pointer;
}