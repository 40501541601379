// Textarea.
.form-label-group textarea::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group textarea:-ms-input-placeholder {
    color: transparent;
}

.form-label-group textarea::-ms-input-placeholder {
    color: transparent;
}

.form-label-group textarea::-moz-placeholder {
    color: transparent;
}

.form-label-group textarea::placeholder {
    color: transparent;
}

.form-label-group textarea:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
}

.form-label-group textarea:not(:placeholder-shown) ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
}

// Select.
.form-label-group select::-webkit-input-placeholder {
   color: transparent;
}

.form-label-group select:-ms-input-placeholder {
    color: transparent;
}

.form-label-group select::-ms-input-placeholder {
    color: transparent;
}

.form-label-group select::-moz-placeholder {
    color: transparent;
}

.form-label-group select::placeholder {
    color: transparent;
}

.form-label-group select:not(:placeholder-shown) {
    padding-top: 1rem;
    padding-bottom: .25rem;
}

.form-label-group select:not(:placeholder-shown) ~ label {
    padding-top: .0rem;
    font-size: 12px;
    color: #777;
}
